<template>
  <div>   
    <b-form-group label="Nome:">
      <b-form-input
        v-model="source.name"
        placeholder="Nome"
        :state="validateState('name')"
      ></b-form-input>
      <b-form-invalid-feedback
        >Nome é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>   

    <b-form-group label="Descrição:">
      <b-form-textarea
        v-model="source.description"
        placeholder="Descrição"
        rows="4"
      ></b-form-textarea>
    </b-form-group> 
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
export default {
  components: {},
  mixins: [validationMixin],

  data: () => ({}),
  validations: {
    source: {     
      name: {
        required
      },      
    }
  },
  computed: {
    ...mapState("source", ["source"])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.source[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.source.$touch();
      return !this.$v.source.$anyError;
    }
  },
  async created() {},
  directives: { mask }
};
</script>
