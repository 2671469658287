<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" v-if="source.id" />
    <div v-else class="text-center p-5 bg-light">
      <b-spinner label="Loading..." variant="info"></b-spinner>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { Header, Form },
  computed: {
    ...mapState("source", ["source"]),
  },
  methods: {
    ...mapActions("source", ["ActionShow", "ActionNew"]),
    async loadData(id) {
      await this.ActionShow({ id: id });
    },
  },
  async created() {
    await this.ActionNew();
    await this.loadData(this.$route.params.id);
  },
};
</script>

<style>
</style>
