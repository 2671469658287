var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.source)?_c('div',{staticClass:"card border-0 radius-bottom-0"},[_c('div',{staticClass:"card-header bg-dark text-white row m-0 pt-4 pb-4"},[_c('div',{staticClass:"col-11"},[_c('h4',{staticClass:"card-tite d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"display-4 mr-3",attrs:{"icon":"user"}}),_c('span',{},[_c('label',{staticClass:"form-label"},[_vm._v("Fonte")]),_c('br'),_vm._v(" "+_vm._s(_vm.source.name)+" ")])],1)]),_c('div',{staticClass:"col-1 d-flex align-items-end"},[(_vm.$can('source:edit'))?_c('router-link',{staticClass:"btn btn-outline-primary mr-2 link-edit",attrs:{"to":{
          name: 'source_edit',
          params: { id: _vm.source.id }
        }}},[_vm._v("Editar")]):_c('router-link',{staticClass:"btn btn-outline-primary mr-2 link-edit d-none",attrs:{"to":{
          name: 'source_show',
          params: { id: _vm.source.id }
        }}},[_vm._v("Visualizar")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }